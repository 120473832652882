import { render, staticRenderFns } from "./Duplicates.vue?vue&type=template&id=397e539e&scoped=true&"
import script from "./Duplicates.vue?vue&type=script&lang=js&"
export * from "./Duplicates.vue?vue&type=script&lang=js&"
import style0 from "./Duplicates.vue?vue&type=style&index=0&id=397e539e&scoped=true&lang=scss&"
import style1 from "./Duplicates.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397e539e",
  null
  
)

export default component.exports