import useAppConfig from '@core/app-config/useAppConfig';
export const duplicatesMixins = {
    data() {
        return {
            items: [],
            modalObject: [],
            config: useAppConfig(),
            disableLoading: '',
            loadingState: true,
            showTable: false,
            duplicateMsg: '',
            tableColumns: [
                {
                    key: 'resource',
                    label: 'Duplicates.tableHead.resource',
                    thStyle: {padding: '0.72rem 0', width: '120px'},
                    tdClass: 'bTableTdDateStyle',
                },
                {
                    key: 'originalDoc',
                    label: 'Duplicates.tableHead.originalDoc',
                    thStyle: {padding: '0.72rem 0', width: '190px'},
                },
                {
                    key: 'numberDuplicate',
                    label: 'Duplicates.tableHead.numberDuplicate',
                    thStyle: {padding: '0.72rem 0', width: '200px'},
                },
                {
                    key: 'transaction',
                    label: 'Duplicates.tableHead.transaction',
                    thStyle: {padding: '0.72rem 0', width: '200px'},
                },
                {
                    key: 'status',
                    label: 'Duplicates.tableHead.status',
                    thStyle: {padding: '0.72rem 0', width: '20px'},
                },
                {
                    key: 'action',
                    label: 'Duplicates.tableHead.action',
                    thStyle: {padding: '0.72rem 0', width: '250px'},
                },
            ],
            locale: {
                filterResource: 'Duplicates.filterResource',
                filterStatus: 'Duplicates.filterStatus',
                noTableData: 'EventLogCard.noMatchRecordFound',
                seeDetails: 'Duplicates.seeDetails',
                filterOptions: 'Duplicates.filterOptions',
                todo: 'Duplicates.todo',
                todoOptions: 'Duplicates.todoOptions',
                handleConfirm: 'Duplicates.handleGroupConfirm',
                confirm: 'Duplicates.confirm',
                resource: 'Duplicates.resource',
                status: 'Duplicates.status',
                duplicatesAlready: 'Duplicates.duplicatesAlready',
                accidentResolveMsg: 'Duplicates.accidentResolveMsg',
                accidentmsg: 'Duplicates.accidentMsg',
            },
            buttonAction: [
                {
                    variant: 'secondaryColor',
                    icon: 'auto-fix',
                    event: this.handleGroup,
                    tooltipMsg: 'Duplicates.handle',
                },
                {
                    variant: 'yellow',
                    icon: 'not-equal-variant',
                    event: this.notDuplicated,
                    tooltipMsg: 'Duplicates.noDuplicates',
                },
                {
                    variant: 'success',
                    icon: 'check',
                    event: this.resolve,
                    tooltipMsg: 'Duplicates.resolve',
                },
            ],
        };
    },

    watch: {
        'pagination.currentPage': {
            handler(page) {
                this.queryParams.page = page;

                this.$useJwt
                    .getDuplicatesList({params: this.queryParams})
                    .then(res => {
                        const {results} = res.data;
                        const {count} = res.data;

                        this.pagination.totalDuplicate = count;

                        let newArray = [];
                        results.forEach(element => {
                            newArray.push({
                                ...element,
                                uuid: element.uuid,
                                resource: element.resource.resource,
                                originalDoc: element.original,
                                numberDuplicate: element.group_size,
                                transaction: element.transaction_id,
                                status: element.resolved
                                    ? {
                                          label: 'Duplicates.solve',
                                          value: 'solved',
                                      }
                                    : {
                                          label: 'Duplicates.unsolved',
                                          value: 'unsolved',
                                      },
                            });
                        });

                        this.items = newArray;

                        this.loadingState = false;
                        this.showTable = true;
                    });
            },
        },
        getLang(value) {
            if (this.resource) {
                this.resource = this.$t('Duplicates.resource').find(
                    element => element.value == this.resource.value
                );
            }

            if (this.status) {
                this.status = this.$t('Duplicates.status').find(
                    element => element.value == this.status.value
                );
            }
        },
    },

    computed: {
        originalText() {
            if (this.getLang == '') {
                return this.swedishLang
                    ? 'original_swe_text'
                    : 'original_eng_text';
            } else
                return this.getLang == 'sv'
                    ? 'original_swe_text'
                    : 'original_eng_text';
        },
        handleGroupTitle() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_title' : 'eng_title';
            } else return this.getLang == 'sv' ? 'swe_title' : 'eng_title';
        },
        msg() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        statusColor(status) {
            switch (status) {
                case 'solved':
                    return 'bg-success';
                case 'unsolved':
                    return 'bg-danger';
                case 'potential duplicate':
                    return 'bg-warning';
            }
        },
    },
};
