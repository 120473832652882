<template>
    <div>
        <b-card class="rounded-lg">
            <b-card-text>
                <Loading v-if="loadingState" />
                <b-card no-body v-if="showTable">
                    <div class="m-2">
                        <b-row style="gap: 0.5rem">
                            <b-col
                                cols="12"
                                md="3"
                                class="d-flex align-items-center justify-content-start mb-1 px-0 mb-md-0"
                            >
                                <v-select
                                    v-model="resource"
                                    @input="filterResource"
                                    :placeholder="$t(locale.filterResource)"
                                    class="selected d-inline-block"
                                    :options="$t(locale.resource)"
                                />
                            </b-col>
                            <b-col
                                cols="12"
                                md="3"
                                class="d-flex align-items-center justify-content-start mb-1 px-0 mb-md-0"
                            >
                                <v-select
                                    v-model="status"
                                    @input="filterStatus"
                                    :placeholder="$t(locale.filterStatus)"
                                    class="selected d-inline-block"
                                    :options="$t(locale.status)"
                                />
                            </b-col>

                            <b-table
                                ref="table"
                                responsive
                                :items="items"
                                :fields="tableColumns"
                                primary-key="id"
                                show-empty
                                selectable
                                @row-clicked="onRowSelected"
                                :sort-desc.sync="sortDesc"
                                :sort-by.sync="sortBy"
                                :empty-text="$t(locale.noTableData)"
                                class="position-relative mt-2"
                            >
                                <template #head(resource)="data">
                                    <div
                                        class="d-flex ml-1 justify-content-center"
                                        :style="{
                                            width: data.field.thStyle.width,
                                        }"
                                    >
                                        <span
                                            class="my-auto"
                                            style="margin-right: 10px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(
                                                        'resource',
                                                        true
                                                    )
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(
                                                        '-resource',
                                                        false
                                                    )
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(originalDoc)="data">
                                    <div
                                        class="d-flex"
                                        :style="{
                                            width: data.field.thStyle.width,
                                        }"
                                    >
                                        <span
                                            class="my-auto"
                                            style="margin-right: 10px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(
                                                        'original',
                                                        true
                                                    )
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(
                                                        '-original',
                                                        false
                                                    )
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(numberDuplicate)="data">
                                    <div
                                        class="d-flex"
                                        :style="{
                                            width: data.field.thStyle.width,
                                        }"
                                    >
                                        <span
                                            class="my-auto"
                                            style="margin-right: 10px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(
                                                        'group_size',
                                                        true
                                                    )
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(
                                                        '-group_size',
                                                        false
                                                    )
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template #head(transaction)="data">
                                    <div
                                        class="d-flex justify-content-center"
                                        :style="{
                                            width: data.field.thStyle.width,
                                        }"
                                    >
                                        <span
                                            class="my-auto"
                                            style="margin-right: 10px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(
                                                        'transaction_id',
                                                        true
                                                    )
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(
                                                        '-transaction_id',
                                                        false
                                                    )
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template
                                    #head(status)="data"
                                    :style="{
                                        width: data.field.thStyle.width,
                                    }"
                                >
                                    <div class="d-flex justify-content-center">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 10px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>
                                <template
                                    #head(action)="data"
                                    :style="{
                                        width: data.field.thStyle.width,
                                    }"
                                >
                                    <div class="d-flex justify-content-center">
                                        <span
                                            class="my-auto"
                                            style="margin-right: 10px"
                                            v-text="$t(data.field.label)"
                                        />

                                        <span
                                            class="d-flex flex-column position-relative"
                                        >
                                            <feather-icon
                                                size="10"
                                                @click="
                                                    sortingChanged(data, true)
                                                "
                                                class="cursor-pointer"
                                                icon="ChevronUpIcon"
                                                style="pointer: cursor"
                                                role="button"
                                            />

                                            <feather-icon
                                                @click="
                                                    sortingChanged(data, false)
                                                "
                                                size="10"
                                                class="cursor-pointer"
                                                icon="ChevronDownIcon"
                                                role="button"
                                            />
                                        </span>
                                    </div>
                                </template>

                                <template
                                    #cell(resource)="data"
                                    :style="{
                                        width: data.field.thStyle.width,
                                    }"
                                >
                                    <!-- This is where the resource label name comes from  -->
                                    <div class="d-flex justify-content-center">
                                        <p
                                            class="font-weight-bold text-capitalize mb-0"
                                            v-text="
                                                renderResource(
                                                    getLang,
                                                    data.value
                                                )
                                            "
                                        />
                                    </div>
                                </template>

                                <template #cell(originalDoc)="data">
                                    <div class="d-flex justify-content-center">
                                        <p class="mb-0" v-text="data.value" />
                                    </div>
                                </template>

                                <template #cell(numberDuplicate)="data">
                                    <div class="d-flex justify-content-center">
                                        <p class="mb-0" v-text="data.value" />
                                    </div>
                                </template>
                                <template #cell(transaction)="data">
                                    <div class="d-flex justify-content-center">
                                        <p class="mb-0" v-text="data.value" />
                                    </div>
                                </template>

                                <template #cell(status)="data">
                                    <div
                                        class="d-flex justify-content-center rounded text-white"
                                        :class="statusColor(data.value.value)"
                                    >
                                        <p
                                            class="mb-0 text-center font-weight-bold text-uppercase px-2"
                                            style="
                                                padding: 5px 0px;
                                                font-size: 12px;
                                            "
                                            v-text="$t(data.value.label)"
                                        />
                                    </div>
                                </template>
                                <template #cell(action)="data">
                                    <div
                                        class="d-flex justify-content-center"
                                        style="gap: 0.5rem"
                                    >
                                        <b-button
                                            v-for="(btn, index) in buttonAction"
                                            :key="index"
                                            :variant="btn.variant"
                                            @click="
                                                btn.event(data.item.uuid, data)
                                            "
                                            v-b-tooltip.hover.v-primary
                                            :title="$t(btn.tooltipMsg)"
                                            size="sm"
                                            class="btn-icon position-relative d-flex text-white justify-content-center rounded-circle"
                                            style="height: 30px; width: 30px"
                                        >
                                            <mdicon
                                                class="position-absolute text-white btn-icon-position"
                                                :width="20"
                                                :height="20"
                                                :name="btn.icon"
                                            />
                                        </b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-row>
                    </div>

                    <div class="mx-2 mb-2">
                        <b-row align-h="end">
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                            >
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :total-rows="pagination.totalDuplicate"
                                    :per-page="pagination.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                        <feather-icon
                                            icon="ChevronLeftIcon"
                                            size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                            icon="ChevronRightIcon"
                                            size="18"
                                        />
                                    </template>
                                </b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-card>
            </b-card-text>
        </b-card>

        <ModalDetails
            :uuid="uuid"
            :duplicatesDetails="detailsSelected"
            :modalTitle="modalTitle"
            @notDuplicated="notDuplicated($event)"
            @resolve="resolve($event)"
            @clearSelected="clearSelected"
            @handleGroup="handleGroup($event)"
        />

        <ModalHandleGroup
            :uuid="uuid"
            :duplicatedmsg="duplicatedmsg"
            @firstInstall="firstInstall($event)"
        />
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import vSelect from 'vue-select';
import {duplicatesMixins} from '@/mixins/duplicatesMixins';
import ModalDetails from '@/@core/Duplicates/ModalDetails.vue';
import ModalHandleGroup from '@/@core/Duplicates/ModalHandleGroup.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    mixins: [duplicatesMixins],
    components: {
        vSelect,
        ModalDetails,
        ValidationProvider,
        ValidationObserver,
        ModalHandleGroup,
    },
    data() {
        return {
            config: useAppConfig(),
            searchQuery: '',
            sortDesc: null,
            openModal: false,
            detailsSelected: {},
            sortBy: '',
            valueOptionby: '',
            status: '',
            resource: '',
            uuid: '',
            modalTitle: '',
            optionsByParams: '',
            duplicatedmsg: {},
            queryParams: {},
            field: '',
            capitalizeResource: '',
            pagination: {
                currentPage: 1,
                totalDuplicate: 0,
                perPage: 20,
            },
        };
    },

    computed: {
        language() {
            if (this.getLang == '') {
                return this.swedishLang ? 'Swedish' : 'English';
            } else return this.getLang == 'sv' ? 'Swedish' : 'English';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        filterStatus(value) {
            if (value !== null) {
                if (value.value !== 'all') {
                    this.queryParams[value.params] = value.value;
                    this.getFilterDuplicated();
                } else {
                    delete this.queryParams.resolved;
                    this.getFilterDuplicated();
                }
            } else {
                delete this.queryParams.resolved;
                this.getFilterDuplicated();
            }
        },
        filterResource(value) {
            if (value !== null) {
                if (value.value !== 'all') {
                    this.queryParams[value.params] = value.value;
                    this.getFilterDuplicated();
                } else {
                    delete this.queryParams.resource__resource;
                    this.getFilterDuplicated();
                }
            } else {
                delete this.queryParams.resource__resource;
                this.getFilterDuplicated();
            }
        },

        renderResource(language, valueResource) {
            let resource = this.$t('Duplicates.resource').find(
                element => element.value == valueResource
            );

            return resource.label;
        },

        getFilterDuplicated() {
            this.$useJwt
                .getDuplicatesList({params: this.queryParams})
                .then(res => {
                    const {results} = res.data;

                    const {count} = res.data;

                    this.pagination.totalDuplicate = count;

                    let newArray = [];

                    results.forEach(element => {
                        newArray.push({
                            ...element,
                            uuid: element.uuid,
                            resource: element.resource.resource,
                            originalDoc: element.original,
                            numberDuplicate: element.group_size,
                            transaction: element.transaction_id,

                            status: element.resolved
                                ? {
                                      label: 'Duplicates.solve',
                                      value: 'solved',
                                  }
                                : {
                                      label: 'Duplicates.unsolved',
                                      value: 'unsolved',
                                  },
                        });
                    });

                    this.items = newArray;
                });
        },

        getFieldResource(resource) {
            return new Promise((resolve, reject) => {
                this.$useJwt
                    .getFieldMapper({
                        params: {
                            resource,
                        },
                    })
                    .then(res => {
                        const {data} = res.data;
                        this.field = data;
                        let capitalizeResource =
                            resource.charAt(0).toUpperCase() +
                            resource.slice(1);

                        resolve({capitalizeResource});
                    });
            });
        },

        async onRowSelected(value) {
            this.modalTitle = `Original ${value.resource} ${value.originalDoc}`;
            this.uuid = value.uuid;
            this.$bvModal.show('duplicatesDetails');

            this.getFieldResource(value.resource).then(response => {
                let dataArray = [];
                value.matching_fields.forEach(elementField => {
                    let getValue =
                        this.field[this.language][response.capitalizeResource][
                            elementField
                        ];

                    if (typeof getValue == 'object') {
                        for (let valueField in getValue) {
                            dataArray.push(valueField);
                        }
                    } else if (getValue == null) {
                        dataArray.push(elementField);
                    } else dataArray.push(getValue);
                });

                this.detailsSelected = {
                    ...value,
                    matching_fields: dataArray,
                };
            });
        },
        notDuplicated(uuid, value) {
            this.$swal({
                text: this.$t(this.locale.accidentmsg, {
                    originalTitle:
                        this.modalTitle ||
                        `Original ${value.item.resource} ${value.item.originalDoc}`,
                }),
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                denyButtonText: this.$t('UsersCard.cancel'),
                confirmButtonText: this.$t('Duplicates.confirm'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger mr-1',
                },
                buttonsStyling: false,
            }).then(res => {
                if (res.value) {
                    this.$useJwt
                        .updateDuplicates(uuid, {
                            not_duplicate: true,
                        })
                        .then(res => {
                            this.firstInstall('emits');
                            this.$bvModal.hide('duplicatesDetails');
                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.msg],
                                'CheckIcon',
                                'success'
                            );
                        });
                }
            });
        },

        resolve(uuid, value) {
            this.$swal({
                text: this.$t(this.locale.accidentResolveMsg, {
                    originalTitle:
                        this.modalTitle ||
                        `Original ${value.item.resource} ${value.item.originalDoc}`,
                }),
                icon: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                denyButtonText: this.$t('UsersCard.cancel'),
                confirmButtonText: this.$t('Duplicates.confirm'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-danger mr-1',
                },
                buttonsStyling: false,
            }).then(res => {
                if (res.value) {
                    this.$useJwt
                        .updateDuplicates(uuid, {
                            resolved: true,
                        })
                        .then(res => {
                            this.firstInstall('emits');
                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.msg],
                                'CheckIcon',
                                'success'
                            );
                        });
                }
            });
        },

        handleGroup(uuid) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t('Message.wait'),
                    variant: 'warning',
                    text: this.$t('Duplicates.waitHandle'),
                    waiting: true,
                },
            });

            this.$useJwt
                .getHandleGroup(uuid)
                .then(res => {
                    this.uuid = uuid;

                    this.duplicatedmsg = res.data.data;

                    this.$bvModal.show('modalHandleGroup');
                })
                .catch(err => {
                    if (err.response) {
                        const {data} = err.response;

                        this.$toast.clear();

                        this.popupMsg(
                            this.$t('Message.Failed'),
                            data[this.msg],
                            'AlertTriangleIcon',
                            'danger'
                        );
                    }
                });
        },

        clearSelected() {
            this.uuid = '';
            this.duplicatesDetails = {};
            this.modalTitle = '';
            this.$refs.table.clearSelected();
        },

        sortingChanged(sortBy, desc) {
            if (sortBy.hasOwnProperty('column')) {
                // this.sortBy = sortBy.column;
                // this.sortDesc = desc; //Pending
            } else {
                this.queryParams.ordering = sortBy;

                this.$useJwt
                    .getDuplicatesList({params: this.queryParams})
                    .then(res => {
                        const {results} = res.data;

                        const {count} = res.data;

                        this.pagination.totalDuplicate = count;

                        let newArray = [];
                        results.forEach(element => {
                            newArray.push({
                                ...element,
                                uuid: element.uuid,
                                resource: element.resource.resource,
                                originalDoc: element.original,
                                numberDuplicate: element.group_size,
                                transaction: element.transaction_id,
                                status: element.resolved
                                    ? {
                                          label: 'Duplicates.solve',
                                          value: 'solved',
                                      }
                                    : {
                                          label: 'Duplicates.unsolved',
                                          value: 'unsolved',
                                      },
                            });
                        });

                        this.items = newArray;
                    });
            }
        },

        firstInstall(fromEmit) {
            if (fromEmit == '') {
                this.loadingState = true;
                this.showTable = false;
            }

            this.$useJwt.getDuplicatesList().then(res => {
                const {results} = res.data;

                const {count} = res.data;

                this.pagination.totalDuplicate = count;

                let newArray = [];

                results.forEach(element => {
                    newArray.push({
                        ...element,
                        uuid: element.uuid,
                        resource: element.resource.resource,
                        originalDoc: element.original,
                        numberDuplicate: element.group_size,
                        transaction: element.transaction_id,
                        status: element.resolved
                            ? {
                                  label: 'Duplicates.solve',
                                  value: 'solved',
                              }
                            : {
                                  label: 'Duplicates.unsolved',
                                  value: 'unsolved',
                              },
                    });
                });

                this.items = newArray;

                this.loadingState = false;
                this.showTable = true;
            });
        },

        init() {
            if (this.$route.params.resource) {
                this.resource = this.$t('Duplicates.resource').find(
                    element => element.value == this.$route.params.resource
                );

                this.queryParams[this.resource.params] = this.resource.value;

                this.firstInstall();
            } else this.firstInstall();
        },
    },

    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.selected {
    width: 100%;
}

.btn-icon-position {
    top: 15%;
}
.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
